// eslint-disable-next-line no-unused-vars

// polyfills
import './polyfills/forEach' // IE fix forEach-function
import './polyfills/scrollingElement' // (?) fix scrolling
import vh_fix from './polyfills/vh_fix' // Smartphone fix 100vh

import { lazyLoad } from 'unlazy' // lazyloading fallback

// core

(() => {

    if ('ontouchstart' in document.documentElement) {
        document.body.classList.add('is-touch')
    }

    const { userAgent } = window.navigator;
    if (userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') > 0) {
        document.body.classList.add('is-ie')
    }

    const isOnline = window.navigator.onLine;
    if (isOnline) {
        console.log('User ist online!')
    } else {
        console.log('User ist offline!')
    }

})()

document.addEventListener('DOMContentLoaded',(event) =>{

    new vh_fix()

    // change title when leaving the tab/window
                
    let regular_title;
    const away_title = "👀 Peek-A-Boo! (lucasdidthis)";
    let is_away = false;

    function add_away_message(){
        is_away = true;
        regular_title = document.title; 
        document.title = away_title;
    };
    
    window.addEventListener('blur',() =>{ 
        add_away_message();
    });  

    function remove_away_message(){
        is_away = false;
        if( document.title == away_title ){ 
            document.title = regular_title;
        };
    };

    window.addEventListener('focus',() =>{ 
        remove_away_message(); 
    });

}, false)

document.fonts.onloadingdone = () => {

    // add font-styling stuff, only when fonts are loaded
    document.querySelector("body").classList.add("fontsready")

}

window.addEventListener('load', (event) => { 

    /* mailto Spam-Schutz */
    let encEmail = "aGVsbG9AbHVjYXNkaWR0aGlzLmNvbQ=="
    const forms = document.querySelectorAll('a[href="#mailme"]')

    forms.forEach(function (form) {
        form.setAttribute("title", "Email senden")
        form.setAttribute("href", "mailto:".concat(atob(encEmail)))
    })

})
